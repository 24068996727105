import React from "react"
import { graphql, PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
// import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"

import { Heading } from "@/components/heading"
import { Layout } from "@/components/layout"
import { Form } from "@/components/form"
import { ArticleCategories } from "@/components/articleCategories"
import { ArticleList } from "@/components/articleList/index"
import { ArticleListHeading } from "@/components/articleListHeading"

import styles from "./index.module.scss"

/* ==================================================
  画像の読み込み
================================================== */
import calenderBanner from "../../images/blog/calender_banner.jpg"
import tewatashiBanner from "../../images/blog/tewatashi_banner.jpg"

const BlogCategoryPage: React.VFC<PageProps<GatsbyTypes.BlogCategoryQuery>> = ({
  data,
}) => {
  const blogNode = data.allMicrocmsBlog.nodes
  const categoryNode = data.allMicrocmsCategories.nodes
  const categoryName = data.microcmsCategories?.name

  return (
    <Layout>
      <div className={styles.blogWrapper}>
        <div className={styles.blog}>
          <Heading
            level="h1"
            size="Large"
            title={`TAM ECチームのリリースや\nイベント情報をいち早くお届け。`}
            subTitle="ブログ"
          />
          <div className={styles.spacer} aria-hidden="true"></div>
          <ArticleCategories categories={categoryNode} path="blog" />
          <ArticleListHeading title={categoryName} />
          <ArticleList path="blog" articles={blogNode} />
          <ul className={styles.bannerList}>
            <li className={styles.bannerItem}>
              <a
                href="https://apps.shopify.com/deliverydate-production?locale=ja"
                target="_blank"
                rel="noreferrer"
                className={styles.bannerLink}
              >
                <StaticImage
                  src="../../images/blog/calender_banner.jpg"
                  alt="shopifyアプリ カレンダーマスター"
                  loading="lazy"
                  quality={80}
                />
                <p className={styles.bannerText}>
                  簡単な操作で配送希望日時が指定できるアプリ
                </p>
              </a>
            </li>
            <li className={styles.bannerItem}>
              <a
                href="https://apps.shopify.com/tewatashi_production?locale=ja"
                target="_blank"
                rel="noreferrer"
                className={styles.bannerLink}
              >
                <StaticImage
                  src="../../images/blog/tewatashi_banner.jpg"
                  alt="shopifyアプリ テワタシ"
                  loading="lazy"
                  quality={80}
                />
                <p className={styles.bannerText}>
                  日本の事業者に合わせた店舗受け取り&ローカルデリバリーアプリ
                </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Form />
    </Layout>
  )
}

export const query = graphql`
  query BlogCategory($id: String) {
    allMicrocmsBlog(
      sort: { fields: date, order: DESC }
      filter: { category: { elemMatch: { pathName: { eq: $id } } } }
    ) {
      nodes {
        blogId
        date(formatString: "YYYY/MM/DD")
        title
        img {
          height
          url
          width
        }
      }
    }
    allMicrocmsCategories {
      nodes {
        categoriesId
        name
        pathName
      }
    }
    microcmsCategories(pathName: { eq: $id }) {
      name
    }
  }
`

export default BlogCategoryPage
